export const CHECK_AUTH = 'checkAuth';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const USERS_GET_ALL = 'getAllUsers';
export const USERS_GET = 'getUser';
export const USERS_CREATE = 'createUser';
export const USERS_BLOCKED = 'blockedUser';
export const USERS_UPDATE = 'updateUser';
export const USER_INFO = 'userInfo';
export const USER_ME = 'userMe';
export const USERS_SET_TOKEN_FCM = 'userMeSetTokenFcm';
export const USERS_REMOVE_TOKEN_FCM = 'userMeRemoveTokenFcm';

export const TEAMS_GET_ALL = 'getAllTeams';
export const TEAMS_GET = 'getTeams';
export const TEAMS_CREATE = 'createTeams';
export const TEAMS_BLOCKED = 'blockedTeams';
export const TEAMS_UPDATE = 'updateTeams';
export const TEAM_ACTIVE_USER_OR_DEACTIVATE = 'TeamsDeactiveOff';

export const PROJECTS_GET_ALL = 'getAllProjects';
export const PROJECTS_GET = 'getProjects';
export const PROJECTS_CREATE = 'createProjects';
export const PROJECTS_UPDATE = 'updateProjects';
export const PROJECTS_CREATE_TASKS_LIST = 'createProjectsTasksList';
export const PROJECTS_BLOCKED = 'blockedProjects';

export const DEPOSITS_GET_ALL = 'getAllDeposits';

export const TASKS_GET_ALL = 'taskGetAll';
export const TASKS_GET_ALL_MOBILE = 'taskGetAllMobile';
export const TASKS_UPDATE_ITEM_MOBILE = 'taskUpdateItem';
export const TASKS_GET = 'taskGet';
export const TASKS_UPDATE = 'TaskUpdate';
export const TASK_CLOSE = 'TaskClose';
export const TASK_ADD_COMMENT = 'TaskAddComment';
export const TASK_DELETE_COMMENT = 'TaskDeleteComment';
export const TASK_UPDATE_COMMENT = 'TaskUpdateComment';
export const TASK_PHONE_CLIENT = 'TaskCall';

export const REPORTS_GET_ALL = 'reportsGetAll';
export const REPORTS_GET_TEAMS = 'reportsGetTeam';
export const REPORTS_GET_STATS_OPERATOR = 'reportGetStatsOperator';

export const CALL_GET_USERS = 'callUsers';
export const CALL_GET_RECORD = 'callRecord';
export const CALL_UPDATE = 'callUpdateRecord';
export const CALL_CREATE = 'callCreate';
export const CALL_GET_LOGS = 'callGetLogs';

export const SMS_TEMPLATE_GET_ALL = 'getSmsTemplate';
export const SMS_TEMPLATE_GET = 'getOneSmsTemplate';
export const SMS_TEMPLATE_CREATE = 'createSmsTemplate';
export const SMS_TEMPLATE_UPDATE = 'updateSmsTemplate';
export const SMS_SEND = 'smsSend';
export const SMS_LOGS = 'smsLog';
export const SMS_RESEND = 'smsResend';

export const MOBILE_CALL_BLOCKED = 'mobileCallBlocked';

export const BONUS_LIST = 'getBonusList';
export const BONUS_ADD_PLAYER = 'addBonusPlayer';
export const GET_BONUS_LIST_PROJECT = 'getBonusProject';
export const SET_BONUS_LIST_PROJECT = 'setBonusProject';

export const ISSUES_CREATE_CATEGORIES = 'issuesCreateCategories';
export const ISSUES_UPDATE_CATEGORIES = 'issuesUpdateCategories';
export const ISSUES_GET_CATEGORIES = 'issuesGetCategories';
export const ISSUES_CREATE = 'issuesCreate';
export const ISSUES_GET = 'issuesGet';
export const ISSUES_UPDATE = 'issuesPut';

export const GET_SMS_SCHEMAS = 'smsSchemasGet';
