<template>
  <div class="desktop-menu">
    <nav class="nav nav--desktop">
      <router-link
        :to="{ name: 'Users'}"
        class="logo logo--desktop">
        <img
          src="/img/logo.svg"
          alt="">
      </router-link>
      <router-link
        v-if="computedIsAdmin || computedIsTeamLeader"
        :to="{ name: 'Users'}"
        class="nav__item nav__item--desktop">
        <font-awesome-icon
          :icon="dataUserIcon"
          :style="{fontSize: 20}" /> Users
      </router-link>
      <router-link
        v-if="computedIsAdmin || computedIsTeamLeader"
        :to="{ name: 'Teams'}"
        class="nav__item nav__item--desktop">
        <font-awesome-icon
          :icon="dataUsersIcon"
          :style="{fontSize: 20}" /> Teams
      </router-link>
      <router-link
        :to="{ name: 'Projects'}"
        class="nav__item nav__item--desktop">
        <font-awesome-icon
          :icon="dataBriefcaseIcon"
          :style="{fontSize: 20}" /> Projects
      </router-link>
      <router-link
        :to="{ name: computedIsOperator ? 'ReportsDetailOperator' : 'Reports'}"
        class="nav__item nav__item--desktop">
        <font-awesome-icon
          :icon="dataChartIcon"
          :style="{fontSize: 20}" /> Reports
      </router-link>
      <router-link
        v-if="computedIsAdmin"
        :to="{ name: 'Deposits'}"
        class="nav__item nav__item--desktop">
        <font-awesome-icon
          :icon="dataMoneyBill"
          :style="{fontSize: 20}" /> Deposits
      </router-link>
      <router-link
        v-if="computedIsAdmin || computedIsTeamLeader"
        :to="{ name: 'CallsRecords'}"
        class="nav__item nav__item--desktop">
        <font-awesome-icon
          :icon="dataPhone"
          :style="{fontSize: 20}" />Calls
      </router-link>
      <router-link
        v-if="computedIsAdmin || computedIsTeamLeader"
        :to="{ name: computedIsAdmin ? 'sms' : 'SmsLogs'}"
        class="nav__item nav__item--desktop">
        <font-awesome-icon
          :icon="dataSms"
          :style="{fontSize: 20}" />SMS
      </router-link>
    </nav>

    <div class="logout-wrapper">
      <router-link
        :to="{ name: 'Settings'}"
        class="nav__item nav__item--desktop">
        <font-awesome-icon
          :icon="dataCogIcon"
          :style="{fontSize: 20}" />Settings
      </router-link>
      <span
        class="logout nav__item nav__item--desktop"
        @click="$root.$emit('logout')"><font-awesome-icon
          :icon="dataSignOutIcon"
          :style="{fontSize: 20}" /> Logout</span>
      <p>v {{ dataVersion }}</p>
    </div>
  </div>
</template>

<script>
import { version } from '../../../package.json';
import { mapGetters } from 'vuex';
import {
  faBriefcase,
  faChartBar,
  faCog, faPhone,
  faSearch,
  faSignOutAlt, faSms,
  faUser,
  faUsers,
  faMoneyBill
} from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'CallMenu',
  data () {
    return {
      dataVersion: version,
      dataUserIcon: faUser,
      dataUsersIcon: faUsers,
      dataBriefcaseIcon: faBriefcase,
      dataChartIcon: faChartBar,
      dataCogIcon: faCog,
      dataSignOutIcon: faSignOutAlt,
      dataSearch: faSearch,
      dataSms: faSms,
      dataPhone: faPhone,
      dataMoneyBill: faMoneyBill
    };
  },
  computed: {
    ...mapGetters({
      computedIsAdmin: 'auth/isAdmin',
      computedIsTeamLeader: 'auth/isModerator',
      computedIsOperator: 'auth/isOperator'
    })
  }
};
</script>
